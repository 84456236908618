import React, { memo, type RefObject, useEffect, useRef } from 'react';

type DataListProps = { options: string[]; id: string; inputRef: RefObject<HTMLInputElement>; openOnMount?: boolean };
export function DataList({ options, id, inputRef, openOnMount }: DataListProps) {
  const mountedRef = useRef(false);
  useEffect(() => {
    if (openOnMount && !mountedRef.current) {
      mountedRef.current = true;
      setTimeout(() => {
        // prevent focus from being triggered when the user is not interacting with the input
        if (document.activeElement !== inputRef.current) return;
        inputRef.current!.blur();
        inputRef.current!.focus();
      }, 50);
    }
  }, [inputRef, openOnMount, id]);
  return <MemoDataList id={id} options={options} />;
}
// Memoize the datalist since it renders a large amount of options
const MemoDataList = memo(({ options, id }: { options: string[]; id: string }) => (
  // Do not render more than 4000 options or the following error will happen during react refresh
  // react-refresh:278 Uncaught RangeError: Maximum call stack size exceeded
  // at scheduleFibersWithFamiliesRecursively
  <datalist id={id}>
    {options.slice(0, 4000).map((value, index) => (
      <option value={value} key={index} />
    ))}
  </datalist>
));
MemoDataList.displayName = 'MemoDataList';
