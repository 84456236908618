// A debounced input react component
import React, { forwardRef, useEffect, useRef } from 'react';

export type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

// tslint:disable-next-line:no-shadowed-variable only-arrow-functions
export const DebouncedInput = forwardRef<HTMLInputElement, DebouncedInputProps>(
  ({ value: initialValue, onChange, debounce = 500, ...props }: DebouncedInputProps, ref) => {
    const [value, setValue] = React.useState(initialValue);
    // do not trigger useEffect when on change handler changes
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChangeRef.current(value);
      }, debounce);
      return () => clearTimeout(timeout);
    }, [debounce, value]);

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} ref={ref} />;
  }
);
DebouncedInput.displayName = 'DebouncedInput';
