import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

type RmxServiceContextData = {
  refetchMyWorkTable: () => Promise<unknown>;
  refetchServiceTable: () => Promise<unknown>;
  userRoles: string[] | string;
};

const RmxServiceContext = createContext<RmxServiceContextData | undefined>(undefined);

export function useRmxServiceContext() {
  const value = useContext(RmxServiceContext);
  if (!value) throw new Error('useRmxServiceContext must be used within a RmxServiceContextProvider');
  return value;
}

export function RmxServiceContextProvider({ refetchMyWorkTable, refetchServiceTable, userRoles, children }: RmxServiceContextData & PropsWithChildren) {
  const value = useMemo(() => ({ refetchMyWorkTable, refetchServiceTable, userRoles }), [refetchMyWorkTable, refetchServiceTable, userRoles]);
  return <RmxServiceContext.Provider value={value}>{children}</RmxServiceContext.Provider>;
}
