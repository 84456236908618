import type { MyTime } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import { formatDateToTimeString } from '../../utils/date-time';

const col = createServerColumnHelper<MyTime>().accessor;
export const myTimeColumnsDef = () => [
  col('activityCrmId', 'string', {
    header: 'Activity Id',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('customerName', 'string', {
    header: 'Customer',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('entryDate', 'date', {
    meta: { serverFilters: ['date-range'] },
    header: 'Date',
    cell: (info) => {
      const entryDate = info.getValue() as Date;
      return entryDate.toLocaleDateString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('startTime', 'time', {
    header: 'Start Time',
    cell: (info) => {
      const startTime = info.getValue() as Date;
      return startTime.toLocaleTimeString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('endTime', 'time', {
    header: 'End Time',
    cell: (info) => {
      const endTime = info.getValue() as Date;
      return endTime.toLocaleTimeString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('totalTime', 'time', {
    meta: { serverFilters: ['exact-text'] },
    header: 'Total Time',
    cell: (info) => {
      const totalTime = info.getValue() as Date;
      return formatDateToTimeString(totalTime, false);
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('type', 'string', {
    header: 'Type',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  })
];
