import React, { createContext, type ReactNode, useMemo } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';
import { useModal } from '../hooks/useModal';

export type ConfirmParams<T> = {
  title: string;
  message: string;
  positiveText?: string;
  negativeText?: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
  confirmActionParameter?: T;
};

export function GlobalConfirmDialogProvider({ children }: { children: ReactNode }) {
  const modal = useModal<ConfirmParams<unknown>, boolean>();
  const openModal = modal.open;
  const contextValue: GlobalAlertDialogContextValue<unknown> = useMemo(
    () => ({
      confirm: async (params) => {
        return (await openModal(params as unknown as ConfirmParams<unknown>)) ?? false;
      }
    }),
    [openModal]
  );
  return (
    <>
      <GlobalConfirmationDialogContext.Provider value={contextValue}>
        {children}
        <>
          {modal.isOpen && (
            <ConfirmationDialog
              title={modal.param!.title}
              open={modal.isOpen}
              onAccept={async () => {
                await modal.param!.onConfirm?.();
                modal.close(true);
              }}
              onDecline={() => {
                modal.close(false);
                modal.param!.onCancel?.();
              }}
              prompt={modal.param!.message}
              positiveText={modal.param!.positiveText}
              negativeText={modal.param!.negativeText}
            />
          )}
        </>
      </GlobalConfirmationDialogContext.Provider>
    </>
  );
}

export type Confirm<T> = (params: ConfirmParams<T>) => Promise<boolean>;
type GlobalAlertDialogContextValue<T> = {
  confirm: Confirm<T>;
};
export const GlobalConfirmationDialogContext = createContext<GlobalAlertDialogContextValue<unknown> | undefined>(undefined);

export function useConfirmationDialog<T>() {
  const value = React.useContext(GlobalConfirmationDialogContext) as GlobalAlertDialogContextValue<T> | undefined;
  if (!value) {
    throw new Error('Must be used within the context');
  }
  return value;
}
