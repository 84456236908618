export type DateAndTime = { date: Date; time: Date };

const makeTimeOptions = (hour12: boolean): Intl.DateTimeFormatOptions => ({
  hour: '2-digit',
  minute: '2-digit',
  hour12: hour12
});
export function formatDateAndTime(dateAndTime: DateAndTime, twelveHourMode: boolean) {
  const startDate = dateAndTime.date;
  const onSiteTime = dateAndTime.time;
  return `${startDate.toDateString()} - ${onSiteTime.toLocaleTimeString(undefined, makeTimeOptions(twelveHourMode))}`;
}

export function formatDateToTimeString(date: Date, twelveHourMode: boolean, locale?: string) {
  return date.toLocaleTimeString(locale, makeTimeOptions(twelveHourMode));
}
