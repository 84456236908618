import { type Flag } from '../Service.types';
import type { ModalState } from '../../hooks/useModal';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { type ReactNode, useState } from 'react';
import { useRmxServiceApi } from '../api';
import { ConfirmationDialog } from '../../utils/ConfirmationDialog/ConfirmationDialog';
import { getApiKey } from '../../utils/ajax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { getUserInformation } from '../../utils';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '../../utils/widgets/LoadingButton/LoadingButton';
import { GrowingTextArea } from '../../utils/GrowingTextArea';
import s from './ViewFlag.module.scss';
import { hasRole } from '../RmxServiceComponent/RmxServiceComponent';

interface Props {
  state: ModalState<Flag>;
  refetchFlagTable: () => void;
}

export function RmxViewFlag({ state, refetchFlagTable }: Props) {
  // this variable is a flag when this modal is opened from the flags table, and an activity id when opened from the activity or my work table
  const flag = state.param as Flag;
  const [response, setResponse] = useState(''); // this is only used when clearing a flag
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const userInformation = getUserInformation(getApiKey()!);
  const api = useRmxServiceApi();

  const { mutateAsync: claimFlag, isPending: claimFlagPending } = useMutation({
    mutationFn: async () => {
      await api.claimFlag((flag as Flag).id);
      refetchFlagTable();
      state.setIsOpen(false);
    }
  });

  const { mutateAsync: clearFlag } = useMutation({
    mutationFn: async () => {
      await api.clearFlag({
        flagId: (flag as Flag).id,
        response: response
      });
      refetchFlagTable();
      state.setIsOpen(false);
    }
  });

  const hasClaim = (flag as Flag).claimedByUserName !== null;
  const myClaim = (flag as Flag).claimedById === parseInt(userInformation.unique_name);
  const hasResponse = (flag as Flag).respondedById !== null;
  const isTechnician = hasRole('Technician');
  const isSupport = hasRole('Support');
  const isClaimedUser = flag.claimedById === null ? isSupport : parseInt(userInformation.unique_name) === flag.claimedById;
  return (
    <>
      <GenericModal
        contentClassName={s['view-flag-modal-content']}
        dialogClassName={s['view-flag-modal-dialog']}
        open={state.isOpen}
        label={flag ? 'Flag' : 'New Flag'}
        setIsOpen={state.setIsOpen}
        hideFooter={true}
        showClose={true}
      >
        {
          // flag exists so we are viewing it
          <Form>
            <FormRow label='Created Date:' content={`${flag.createdAt.toLocaleDateString()}`} />
            <FormRow label={'Customer'} content={`${flag.siteName ?? 'N/A'}`} />
            <FormRow label={'Activity Id'} content={`${flag.activityCrmId ?? 'None'}`} />
            <FormRow label='Created By:' content={`${flag.createdByUserName ?? 'Pending'}`} />
            <FormRow
              label='Claimed By:'
              isButtonRow={!hasClaim}
              content={
                hasClaim ? (
                  flag.claimedByUserName
                ) : hasResponse ? (
                  <>Flag was not claimed</>
                ) : myClaim || isTechnician ? (
                  <> Pending </>
                ) : (
                  <Row className='mb-3'>
                    <Col md={12}>
                      <LoadingButton
                        style={{ width: '100%' }}
                        label={'Claim'}
                        disabled={!isClaimedUser}
                        variant='secondary'
                        onClick={() => claimFlag()}
                        loading={claimFlagPending}
                      />
                    </Col>
                  </Row>
                )
              }
            />
            <FormRow label='Reason:' content={<GrowingTextArea readOnly={true} value={flag.reason} />} />
            <FormRow label='Responded By:' content={`${flag.respondedByUserName ?? 'Pending'}`} />
            <FormRow
              label='Response:'
              content={
                <GrowingTextArea
                  readOnly={hasResponse || !isSupport}
                  setValue={setResponse}
                  placeholder={isSupport ? 'Enter response here...' : 'Pending response...'}
                  value={flag.response !== null && flag.response !== '' ? flag.response : response}
                />
              }
            />

            {!hasResponse && (
              <ButtonFullWidth
                variant='secondary'
                disabled={!isClaimedUser}
                onClick={() => {
                  if (!response) {
                    alert('Please provide a response to clear the flag');
                    return;
                  }
                  setOpenConfirmationDialog(true);
                }}
              >
                {isClaimedUser ? (
                  <>Clear Flag</>
                ) : (
                  <>
                    <>Clear Flag</> <FontAwesomeIcon icon={faLock} />
                  </>
                )}
              </ButtonFullWidth>
            )}

            <ConfirmationDialog
              title={' '}
              open={openConfirmationDialog}
              onAccept={async () => {
                await clearFlag();
              }}
              onDecline={() => {
                setOpenConfirmationDialog(false);
              }}
              prompt='Are you sure you want to clear this flag?'
              positiveText='Yes'
              negativeText='Continue Editing'
            />
          </Form>
        }
      </GenericModal>
    </>
  );
}

export const FormRow = ({ label, content, isButtonRow = false }: { label: string; content: ReactNode; isButtonRow?: boolean }) => (
  <Row className={`mb-3  ${isButtonRow ? 'align-items-md-center' : ''}`}>
    <Col md={3} className={`${isButtonRow ? 'mb-3 ' : ''}`}>
      <Form.Label>
        <strong>{label}</strong>
      </Form.Label>
    </Col>
    <Col md={9}>{content}</Col>
  </Row>
);

export const ButtonFullWidth = ({
  disabled,
  variant,
  onClick,
  children
}: {
  disabled?: boolean;
  variant: string;
  onClick: () => void;
  children: ReactNode;
}) => (
  <Button style={{ display: 'block', width: '100%' }} disabled={disabled} variant={variant} onClick={onClick}>
    {children}
  </Button>
);
