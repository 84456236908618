import { FormCheck } from 'src/components/util/Controls';
import React, { type CSSProperties, useId} from 'react';
import { useBootStrapContext } from '../../../hooks/bootstrap-context';
import s from './CheckBox.module.scss';
import clsx from 'clsx';

interface InputProps {
  disabled?: boolean;
  label: string;
  id?: string;
  className?: string;
  onChange: (value: boolean) => void;
  value: boolean;
  style?: CSSProperties;
}

export function CheckBox({ id, label, onChange, value, disabled, className, style }: InputProps) {
  const genId = useId();
  const { version } = useBootStrapContext();
  if (version === 4) {
    return (
      <div className={clsx("custom-control custom-checkbox col-form-label", s['custom-control'], className)}>
        <input type="checkbox" className="custom-control-input" checked={value} id={genId} onChange={() => onChange(!value)} />
        <label className={clsx("custom-control-label", s['bs-4-label'])} htmlFor={genId}>{label}</label>
      </div>
    );
  }
  return (
    <FormCheck
      // padding top 0 overrides the dumb modifications in portal
      className={'pt-0 ' + (className ?? '')}
      type="checkbox"
      label={label}
      style={style}
      id={`${id ?? 'checkbox'}-${genId}`}
      checked={value}
      onChange={() => onChange(!value)}
      disabled={disabled}
    />
  );
}
