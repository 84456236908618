import { createContext, type PropsWithChildren, useContext } from 'react';

const BootstrapContext = createContext({version: 5});

export function useBootStrapContext() {
  return useContext(BootstrapContext) ?? {version: 5};
}

export function BootStrapContextProvider(props: PropsWithChildren & {version: number}) {
  return <BootstrapContext.Provider value={{version: props.version}} >
    {props.children}
  </BootstrapContext.Provider>;
}
