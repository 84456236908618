import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';
import { useRmxServiceApi } from '../../rmx-service/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { type ContactInfo } from 'src/portal/rmx-service/Service.types';

type ContactContextValueType = {
  customerContacts: ContactInfo[];
  workOrderContacts: ContactInfo[]; // TODO create a type specific for this work order contact
  agreementContacts: ContactInfo[];
  removeWorkOrderContact: (workOrderContactId: number) => Promise<void>; // TODO extract into a type
  upsertContact: (contact: ContactInfo) => Promise<void>;
  addContactToWorkOrder: (contact: number) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
};

const ContactContext = createContext<ContactContextValueType | null>(null);

export function useContactContext() {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error('useContactContext must be used within a ContactContextProvider');
  }
  return context;
}

export function ContactContextProvider({
  workOrderId,
  customerId,
  agreementId,
  children
}: { workOrderId: number; customerId: number; agreementId: number | null } & PropsWithChildren) {
  const api = useRmxServiceApi();
  const {
    data: contacts,
    refetch,
    isFetching: isFetchingContacts,
    isRefetching: isRefetchingContacts,
    isError: errorQuerying
  } = useQuery({
    queryKey: ['contacts', customerId, workOrderId, agreementId],
    queryFn: async () => {
      return await api.getContacts({ customerId, workOrderId, agreementId });
    },
    refetchOnWindowFocus: false
  });

  const {
    mutateAsync: upsertContact,
    isPending: upsertingContact,
    isError: errorUpsertingContact
  } = useMutation({
    mutationFn: async (contact: ContactInfo) => {
      await api.upsertContact(customerId, contact);
      await refetch();
    }
  });

  const {
    mutateAsync: addWorkOrderContact,
    isPending: addingWorkOrderContact,
    isError: errorAddingWorkOrderContact
  } = useMutation({
    mutationFn: async (contactId: number) => {
      await api.addContactToWorkOrder(workOrderId, contactId);
      await refetch();
    }
  });

  const {
    mutateAsync: removeWorkOrderContact,
    isPending: removingWorkOrderContact,
    isError: errorRemovingWorkOrderContact
  } = useMutation({
    mutationFn: async (workOrderContactId: number) => {
      await api.removeContactFromWorkOrder(workOrderContactId);
      await refetch();
    }
  });

  const isLoading = isFetchingContacts || isRefetchingContacts || upsertingContact || addingWorkOrderContact || removingWorkOrderContact;
  const isError = errorQuerying || errorUpsertingContact || errorAddingWorkOrderContact || errorRemovingWorkOrderContact;

  const contextValue = useMemo(
    () =>
      ({
        customerContacts: contacts?.customerContacts ?? [], //Contacts that are already in the work order are filtered out of the customer contacts by the backend
        workOrderContacts: contacts?.workOrderContacts ?? [],
        agreementContacts: contacts?.agreementContacts ?? [],
        upsertContact: upsertContact,
        addContactToWorkOrder: addWorkOrderContact,
        isLoading: isLoading,
        isError: isError,
        removeWorkOrderContact: removeWorkOrderContact
      }) satisfies ContactContextValueType,
    [
      contacts?.customerContacts,
      contacts?.workOrderContacts,
      contacts?.agreementContacts,
      upsertContact,
      addWorkOrderContact,
      isLoading,
      isError,
      removeWorkOrderContact
    ]
  );

  return <ContactContext.Provider value={contextValue}>{children}</ContactContext.Provider>;
}
