import {combineReducers} from 'redux';
import {applyDPTemplate, type DPTemplateAssignmentTemplate, remapTriggersOnDPTemplateAssignmentTemplate, sortDPTemplate} from '../abstract/dataPointTemplate';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {type RevisionEntity} from '../../types/Revision';
import {type SpTemplateFormFields} from './spTemplate';
import {type CommonState} from '../index';
import {spTypeStore} from './spType';
import {getDataPointById} from './dataPoint';
import {removePropertyRecursively} from '../../util/object';
import {createAction} from 'typesafe-actions';
import {type IdDictionary} from '../../util';

export interface SpTemplateForm {
  template: SpTemplateFormFields;
  revision: SpTemplateRevision;
}

export interface SpTemplateRevision extends DPTemplateAssignmentTemplate, RevisionEntity {
  id: number;
  systemProcessTemplateId: number;
}

export const mergeRevisions = createAction('SP_TEMPLATE_REVISION/MERGE')<IdDictionary<SpTemplateRevision>>();

export const makeMarshalSpTemplateRevision = (state: CommonState) => (revision: SpTemplateRevision, spTypeId: number) => {
  sortDPTemplate(revision);
  return spTypeId ? makeApplySpType(state)(revision, spTypeId) : revision;
};

export function cloneSpTemplateRevision(rev: SpTemplateRevision): SpTemplateRevision {
  const idsRemoved = {
   ...removePropertyRecursively(rev, 'id'), systemProcessTemplateId: 0
  };
  return remapTriggersOnDPTemplateAssignmentTemplate(idsRemoved);
}

export const makeApplySpType = (state: CommonState) => (revision: SpTemplateRevision, spTypeId: number): SpTemplateRevision => {
  const spType = spTypeStore.selectors.getById(state)(spTypeId!);
  return applyDPTemplate(getDataPointById(state), revision, spType);
};

const actions = createStandardActions(placeholder<SpTemplateRevision>(), 'SP_TEMPLATE_REVISIONS/SET', 'SP_TEMPLATE_REVISIONS/SAVE');
export type SpTemplateRevisionActions = GetActions<typeof actions> | ReturnType<typeof mergeRevisions>;
export const spTemplateRevisions = combineReducers({items:
    standardItemsReducer<SpTemplateRevision, SpTemplateRevisionActions>(actions)
      .handleAction(mergeRevisions, (state, action) => ({...state, ...action.payload}))
});
export const spTemplateRevisionStore = {
  selectors: createStandardSelectors(placeholder<SpTemplateRevision>(), s => getEntities(s).spTemplateRevisions),
  actions: {
    ...actions,
    merge: mergeRevisions
  }
} as const;
