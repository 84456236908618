import React, { type ForwardedRef, type MouseEventHandler, type PropsWithChildren, type RefObject } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterEmpty } from '../../../common/util';
import { PortalToBody } from '../PortalToBody';

export type EllipsisDropdownItem = { label: string; onClick: () => void };
type EllipsisDropdownProps = {
  toggleClassName?: string;
  items: (EllipsisDropdownItem | null | false | undefined)[];
  toggleRef?: RefObject<HTMLElement | undefined>;
  dropDownRef?: RefObject<HTMLDivElement>;
  menuRef?: RefObject<HTMLDivElement>;
  forceShow?: boolean;
  menuClassName?: string;
};

/**
 * A dropdown menu that has a toggle that is an ellipsis icon
 */
export function EllipsisDropdown({ items: itemsProp, toggleClassName, toggleRef, dropDownRef, menuRef, forceShow, menuClassName }: EllipsisDropdownProps) {
  const items = filterEmpty(itemsProp);
  if (items.length === 0) {
    return null;
  }
  return (
    <Dropdown ref={dropDownRef} show={forceShow}>
      <Dropdown.Toggle as={CustomToggle} className={toggleClassName} ref={toggleRef} />
      <PortalToBody>
        <Dropdown.Menu className={menuClassName} style={{ background: '#23272B', zIndex: 1100 }} ref={menuRef}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Dropdown.Item style={{ background: '#23272B' }} className="text-white" onClick={() => {
                item.onClick();
              }
              }>
                {item.label}
              </Dropdown.Item>
              {index < items.length - 1 && <Dropdown.Divider className="bg-grey" />}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </PortalToBody>
    </Dropdown>
  );
}

const CustomToggle = React.forwardRef(
  ({ children, onClick, className: classNameProp }: PropsWithChildren & { onClick: MouseEventHandler; className?: string }, ref) => {
    const className = classNameProp?.replace('dropdown-toggle', ''); // remote the dropdown toggle as we don't want it
    return (
      <Button size={'sm'} variant={'primary'} ref={ref as ForwardedRef<HTMLButtonElement>} className={className} onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
        <FontAwesomeIcon
          icon={'ellipsis-v'}
        />
        {children}
      </Button>
    );
  }
);
CustomToggle.displayName = 'CustomToggle';
