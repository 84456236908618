import React from 'react';
import {BootstrapFormGroup, type BootStrapFormGroupProps} from './BootstrapFormGroup';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

export type DefinitionTypes = 'row' | 'col' | React.ElementType;

export type FormGroupProps = Omit<BootStrapFormGroupProps, 'as'> & {as?: DefinitionTypes};
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const FormGroup = ({as, ...props}: FormGroupProps) => <BootstrapFormGroup {...props} as={GetAs(as) as any}/>;

const GetAs = (type?: DefinitionTypes) =>
  type === 'row' ? Row :
  type === 'col' ? Col :
  type;
