import React, { createContext, type ReactNode, useContext, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import s from './MenuModal.module.scss';
import { type ActionConfig, type ActionHandler, ActionModal } from '../ActionModal/ActionModal';
import { RmxCreateFlag } from '../../rmx-service/Flags/RmxCreateFlag';
import { useModal } from '../../hooks/useModal';

interface Props<TResource> {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  menuConfig: MenuConfig<any>;
  actionConfig: ActionConfig<any> | null;
  resource: TResource;
}

export function MenuModal({ open, setIsOpen, menuConfig, resource, actionConfig }: Props<unknown>) {
  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const createFlagModal = useModal<{ siteName?: string }>();
  const handleAction = (action: keyof MenuConfig<any>) => {
    const actionHandler = menuConfig[action];
    if (actionHandler) {
      actionHandler(resource);
    }
    if (action === 'action') {
      setActionModalOpen(true);
    }

    if (action === 'flag') {
      console.log(`flagging site: ${resource}`);
      void createFlagModal.open({ siteName: resource as string });
    }

    setIsOpen(false);
  };

  return (
    <>
      <Modal dialogClassName={s['modal_dialog']} contentClassName={s['modal_content']} show={open} centered={true}>
        <ModalHeader style={{ justifyContent: 'center', height: 'auto', borderBottom: '1px solid #000000', color: '#FFFFFF', backgroundColor: '#262626' }}>
          {menuConfig.getLabel(resource)}
        </ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: '10px', color: '#FFFFFF', backgroundColor: '#262626' }}>
          {menuConfig['details'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('details')}>
              Details
            </Button>
          )}
          {menuConfig['service_details'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('service_details')}>
              Details{' '}
            </Button>
          )}
          {menuConfig['quote_invoice_details'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('quote_invoice_details')}>
              Details{' '}
            </Button>
          )}
          {menuConfig['service_flag'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('service_flag')}>
              Flag
            </Button>
          )}
          {menuConfig['open'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('open')}>
              Open
            </Button>
          )}
          {menuConfig['flag'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('flag')}>
              Flag
            </Button>
          )}
          {menuConfig['request'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('request')}>
              Request
            </Button>
          )}
          {menuConfig['view_requests'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('view_requests')}>
              List Requests
            </Button>
          )}
          {menuConfig['submit_requests'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('submit_requests')}>
              Submit Request
            </Button>
          )}
          {menuConfig['bypass'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass')}>
              Bypass
            </Button>
          )}
          {menuConfig['bypass_add'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass_add')}>
              Add Bypass
            </Button>
          )}
          {menuConfig['bypass_clear'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass_clear')}>
              Clear Bypass
            </Button>
          )}
          {menuConfig['update'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('update')}>
              Update
            </Button>
          )}
          {menuConfig['action'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('action')}>
              Action
            </Button>
          )}
          {menuConfig['open_site'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('open_site')}>
              Open Site
            </Button>
          )}
          {menuConfig['open_asset'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('open_asset')}>
              Open Asset
            </Button>
          )}
          {menuConfig['alerts'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('alerts')}>
              Alerts
            </Button>
          )}
          {menuConfig['rmx_service'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('rmx_service')}>
              Service
            </Button>
          )}
          {menuConfig['documents'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('documents')}>
              Documents
            </Button>
          )}
          {menuConfig['graphing'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('graphing')}>
              Graphing
            </Button>
          )}
          {menuConfig['reports'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('reports')}>
              Reports
            </Button>
          )}
          {menuConfig['edit'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('edit')}>
              Edit Notes
            </Button>
          )}
          {menuConfig['componentLife'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('componentLife')}>
              Component Life
            </Button>
          )}
          {menuConfig['notification_setup'] && (
            <Button variant={'info'} className={s['button']} onClick={() => handleAction('notification_setup')}>
              Notification Setup
            </Button>
          )}
          {menuConfig['disabled_open_asset'] && (
            <Button disabled={true} variant={'info'} className={s['button']} onClick={() => handleAction('disabled_open_asset')}>
              Open Asset
            </Button>
          )}
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: '1px solid #000000', backgroundColor: '#262626' }}>
          <Button className={s['button']} variant='secondary' onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
        </ModalFooter>
      </Modal>
      {actionConfig && <ActionModal resource={resource} open={isActionModalOpen} setIsOpen={setActionModalOpen} actionConfig={actionConfig} />}
      {<RmxCreateFlag state={createFlagModal} />}
    </>
  );
}

export function GlobalMenuModalProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [resource, setResource] = useState<unknown | null>(null);
  const [menuConfig, setMenuConfig] = useState<MenuConfig<any> | null>(null);
  const [actionButtonsConfig, setActionButtonsConfig] = useState<ActionConfig<any> | null>(null);

  const contextValue: GlobalShowMenuContextValue<any> = useMemo(
    () => ({
      showMenu: (msg, menuConfig, actionConfig) => {
        setResource(msg);
        setMenuConfig(menuConfig);
        setActionButtonsConfig(actionConfig || null);
        setIsOpen(true);
      }
    }),
    [setResource, setIsOpen]
  );

  return (
    <>
      <GlobalMenuModalContext.Provider value={contextValue}>
        {children}
        {menuConfig && <MenuModal resource={resource} open={isOpen} setIsOpen={setIsOpen} menuConfig={menuConfig} actionConfig={actionButtonsConfig} />}
      </GlobalMenuModalContext.Provider>
    </>
  );
}

// Menu action handler allows us to define the handlers for our buttons with any context type
export type MenuActionHandler<TResource> = (resource: TResource) => void;

// MenuConfig allows us to define the buttons that we want to show and provide custom handlers for them
export type MenuConfig<TResource> = {
  getLabel: (r: TResource) => string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  quote_invoice_details?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_details?: MenuActionHandler<TResource>;
  details?: MenuActionHandler<TResource>;
  open?: MenuActionHandler<TResource>;
  edit?: MenuActionHandler<TResource>;
  bypass?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bypass_add?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bypass_clear?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  request?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  view_requests?: ActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  submit_requests?: ActionHandler<TResource>;
  update?: MenuActionHandler<TResource>;
  action?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  open_site?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  open_asset?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  disabled_open_asset?: MenuActionHandler<TResource>;
  alerts?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rmx_service?: MenuActionHandler<TResource>;
  documents?: MenuActionHandler<TResource>;
  graphing?: MenuActionHandler<TResource>;
  reports?: MenuActionHandler<TResource>;
  componentLife?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  notification_setup?: MenuActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_flag?: MenuActionHandler<TResource>;
  flag?: MenuActionHandler<TResource>;
};

export type ShowMenu<TResource> = (resource: TResource, menuConfig: MenuConfig<TResource>, actionConfig?: ActionConfig<TResource>) => void;

type GlobalShowMenuContextValue<TResource> = {
  showMenu: ShowMenu<TResource>;
};

export const GlobalMenuModalContext = createContext<GlobalShowMenuContextValue<any>>({
  // tslint:disable-next-line:no-empty
  showMenu: () => {}
});

export function useShowMenu<TResource>(): GlobalShowMenuContextValue<TResource> {
  return useContext(GlobalMenuModalContext);
}
