import { type AxiosError } from 'axios';

export function isBadRequest(e: any) {
  return getErrorStatus(e) === 400;
}

export function isServerError(e: any) {
  return getErrorStatus(e) >= 500 && getErrorStatus(e) < 600;
}

export function isUnknownError(e: any) {
  return getErrorStatus(e) === 0;
}

export function getErrorStatus(e: any): number {
  if (isAxiosError(e)) {
    if (e.response) return e.response.status;
    if (e.request instanceof XMLHttpRequest) {
      return e.request.status;
    }
  }
  return -1;
}

export interface AxiosErrorMessageParams {
  connectionMsg?: string;
  badRequestMsg?: string;
  four04Msg?: string;
  forbiddenMsg?: string;
  unauthorized?: string;
  serverError?: string;
}

export function isAxiosError(e: unknown): e is AxiosError<{ message: string; errors: { [key: string]: string[] } }> {
  return typeof e === 'object' && e !== null && ('response' in e || 'request' in e);
}

export function get400ResponseOrNull<TResponse>(e: unknown) {
  if (isAxiosError(e) && e.response?.status === 400) {
    return e.response.data as TResponse;
  }
  return null;
}

export function handleAxiosError(e: unknown, params: AxiosErrorMessageParams = {}) {
  const connectionMsg = params.connectionMsg ?? 'Could not connect. Please check your internet connection.';
  const is401Or400 = isAxiosError(e) && (e.response?.status === 401 || e.response?.status === 400);
  // tslint:disable-next-line:no-console
  console.error(e);
  if (!isAxiosError(e)) {
    // reportException(e);
    return 'Unknown Error';
  }
  if (!is401Or400) {
    // reportException(e);
  }

  if (e.response) {
    if (e.response.status === 400) {
      const defaultMessage =
        typeof e.response.data === 'object' && e.response.data.message
          ? e.response.data.message
          : typeof e.response.data === 'string'
            ? e.response.data
            : 'Bad Request';
      // if (e.response.data.errors && setErrors) {
      //   const errors = e.response.data.errors;
      //   // setErrors(convertAspNetCoreErrorsToFormikErrors(errors));
      //   if (Array.isArray(errors[''])) {
      //     return errors[''].join('\n');
      //   }
      //   return '';
      // }
      return params.badRequestMsg || defaultMessage;
    } else if (e.response.status === 401) {
      return params.unauthorized || 'Unauthorized';
    } else if (e.response.status === 403) {
      return params.forbiddenMsg || 'Forbidden';
    } else if (e.response.status === 404) {
      return params.four04Msg || 'Not Found';
    } else if (e.response.status === 500) {
      // tslint:disable-next-line:no-console
      console.error(e);
      return params.serverError || 'An error occurred on the server. Please refresh the page or try again later.';
    } else if (e.response.status === 0) {
      // tslint:disable-next-line:no-console
      console.error(e);
      return connectionMsg;
    } else {
      // tslint:disable-next-line:no-console
      console.error(e);
      return 'Unknown Error';
    }
  } else if (e.request instanceof XMLHttpRequest) {
    if (e.request.status === 0) {
      return connectionMsg;
    } else {
      // tslint:disable-next-line:no-console
      console.error(e);
      return 'Unknown Error';
    }
  }
  throw e;
}
