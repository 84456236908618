import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

type CameraOrGalleryInputProps = {
  defaultFileName?: string[] | null;
  setFiles: (files: File[]) => void; // Updated to accept an array of files or null
  resetFileNamesTrigger?: number;
  disabled?: boolean;
};

export function CameraOrGalleryInput({ disabled, defaultFileName, resetFileNamesTrigger, setFiles }: CameraOrGalleryInputProps) {
  const [fileNames, setFileNames] = useState<string[] | null | undefined>(defaultFileName);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Reset fileNames when resetTrigger changes
    if (fileInputRef.current?.value && resetFileNamesTrigger !== 0) {
      setFileNames([]);
      // https://stackoverflow.com/questions/1703228/how-can-i-clear-an-html-file-input-with-javascript explains why we need to set the file input to null instead of an empty string
      // @ts-ignore
      fileInputRef.current.value = null; // Clear the file input
    }
  }, [resetFileNamesTrigger]); // Listening for changes to resetTrigger

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event !== null && event.target.files ? Array.from(event.target.files) : null;
    setFiles(files ?? []); // Updated to handle multiple files
    // Update the file names state for display
    setFileNames(files ? files.map((file) => file.name) : defaultFileName ?? []);
  };

  const triggerFileInput = (action: 'upload' | 'takePhoto') => {
    if (fileInputRef.current) {
      if (action === 'takePhoto') {
        // Only set capture for taking photos
        fileInputRef.current.setAttribute('capture', 'environment');
      } else {
        // Remove attribute for uploading photos to access the gallery
        fileInputRef.current.removeAttribute('capture');
      }
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <Form.Control
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the file input
        onChange={handleFileChange}
        type='file'
        accept='image/*,.pdf'
        multiple={false}
      />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
        <Button disabled={disabled} onClick={() => triggerFileInput('upload')} variant='secondary'>
          Upload Photo
        </Button>
        <Button disabled={disabled} onClick={() => triggerFileInput('takePhoto')} variant='secondary'>
          Take Photo
        </Button>
      </div>
      {fileNames && fileNames.length > 0 && <div>Uploaded: {fileNames.join(', ')}</div>}
    </>
  );
}
